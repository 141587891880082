#zoomedDisplay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.6);
}

.zoomedNavButton {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  border: 0;
  z-index: 1;
  height: 9%;
  width: 5%;
  border-radius: 8%;
  position: relative;
  background-color: var(--button-bg);
  align-self: center;
  cursor: pointer;
  font-family: "FontAwesome";
}

.zoomedNavButton:hover {
  -moz-osx-font-smoothing: grayscale;
  backface-visibility: hidden;
  background-color: rgb(0, 0, 0, 0.7);
  transition: transform 0.25s ease-out;
}

#zoomedBackgroundDiv {
  margin: 0 1rem 0 1rem;
  width: 94vw;
  height: 97vh;
  background-color: var(--display-bg);
  padding: 0.5rem;
}

#zoomedImageDiv {
  display: flex;
  justify-content: center;
  height: 94%;
  width: 100%;
  max-height: 94vh;
  max-width: 94vw;
}

#zoomedMainImage {
  background-color: black;
  width: 100%;
  float: left;
  max-height: 94vh;
  max-width: 100vw;
  object-fit: contain;
}

#zoomedLabelContainer {
  background-color: black;
  margin-left: auto;
  margin-right: auto;
  float: center;
  width: 100%;
  max-width: 100vw;
  height: 6%;
  overflow-y: scroll;
  scrollbar-color: var(--display-bg);
}

#zoomedLabelContainer::-webkit-scrollbar {
  width: 12px;
  background-color: var(--display-bg);
}

#zoomedLabelContainer::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: white;
}

@media only screen and (max-width: 500px) {
  #zoomedImageDiv {
    height: 85%;
  }

  #zoomedLabelContainer {
    height: 15%;
  }
}

@media only screen and (max-width: 700px) {
  #zoomedImageDiv {
    height: 80%;
  }

  #zoomedLabelContainer {
    height: 20%;
  }
}

@media only screen and (max-width: 700px) {
  .zoomedNavButton {
    height: 30%;
  }

  #leftZoomedButton {
    left: 0.5rem;
  }

  #rightZoomedButton {
    right: 0.5rem;
  }
}

@media only screen and (max-width: 1100px) {
  #zoomedBackgroundDiv {
    margin: 0;
  }
}
