#contactWrapper {
  width: 100%;
  background-color: rgba(0, 0, 0, 0);
  display: flex;
  justify-content: center;
  padding: 0 6rem 0 6rem;
}

#insetContact {
  padding: 7rem 3rem 3rem;
  min-width: 60%;
  background-color: white;
}

#contactCards {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

#contactCards > section {
  width: 15rem;
  border-radius: 30px;
  padding: 2.5rem 1.5rem 1.5rem 1.5rem;
  margin: 1rem 2rem;
  border: 2px solid var(--green);
}

#contactCards > section > i {
  font-size: 4rem;
  margin-bottom: 0.5rem;
  color: black;
}

#contactCards > section > p {
  color: black;
}

#contactInfoContent {
  border-radius: 10px;
  font-size: 2.5rem;
  margin-top: 2rem;
  margin-left: 50%;
  transform: translateX(-50%);
  padding: 1.5rem;
  width: 90%;
  color: black;
  background-color: var(--green);
}

#contactInfoContent > p {
  width: 80%;
  margin: 0.4rem auto;
}

@media only screen and (max-width: 700px) {
  #contactWrapper {
    padding: 0 2rem 0 2rem;
  }

  #contactCards > section {
    width: auto;
    margin: 0 2rem 1rem 2rem;
  }

  #contactInfoContent {
    width: 100%;
    font-size: 0.8rem;
  }

  #insetContact {
    padding: 8rem 1rem 1rem;
  }
}

@media only screen and (min-width: 700px) and (max-width: 800px) {
  #contactInfoContent {
    font-size: 1.75rem;
    width: 100%;
  }

  #contactCards > section {
    margin: 0 2rem 1rem 2rem;
  }

  #insetContact {
    padding: 3rem 1rem 1rem 1rem;
  }

  #contactWrapper {
    padding: 0 2rem;
  }
}
